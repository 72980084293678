import React, { Component } from 'react'
import './index.css'

export default class partners extends Component {
    render() {
        return (
            <section className="twelve columns partners">
                <div className="container">
                    <h2 >Our Partners</h2>
                    <p>We thank our partners who have helped us with our work</p>
                    <div className="row">
                        
                        <div className="three columns part">
                            <img src="./images/gals_forum.png" height={75} alt="" />
                        </div>
                        <div className="three columns part">
                            <img src="./images/lifecare.png" height={75} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
