import React, { Component } from 'react'
import './topnav.css'

class Topnav extends Component {

    state ={
        auto_search:false
    }

    getStyle(){
        if(this.props.scrolled){
           return "nav-noshow"
        }else{
          return "nav-show"
        }
      }

      auto_display =()=>{
           console.log("clicked")
           this.setState({ auto_search: !this.state.auto_search });
      }

      getauto(){
          if(this.state.auto_search){
              return "new_auto"
          }else{
              return " "
          }
      }

    toTopStyle(){
        if(this.props.scrolled){
            return "totop-show"
        }else{
        }   return "totop-noshow"
    }

    handleToTop=()=>{
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }

    render() {
        return (
            <React.Fragment>
            <div className={"totop " + this.toTopStyle()} onClick={this.handleToTop}>
            <svg className="arrow" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 960 560" enableBackground="new 0 0 960 560"><g id="Rounded_Rectangle_33_copy_4_1_"><path fill="#fff" d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937
 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937
 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"></path></g></svg>
            </div>
            <div className={"top-nav " + this.getStyle()} >
                <div className="container">
                    <div className="offset-by-six six columns">
                        <div className="six columns">
                            <input className="topsearch" onClick={this.auto_display} placeholder="Search..." type="text" name="search" />
                            <div className={"auto_search "+this.getauto()}>
                                <span className="auto_content">
                                <h5>Search Results</h5>
                                <p>Nothing found at this time</p>
                                </span>
                            </div>
                        </div>
                        <div className="topsocial six columns">
                            <ul>
                                <a href="https://www.facebook.com/abenakyofoundation/"  target="blank">
                                   <li><i className="fa fa-facebook" aria-hidden="true"></i></li>
                                </a>
                                <a href="https://twitter.com/QAFoundation1"  target="blank">
                                    <li><i className="fa fa-twitter" aria-hidden="true"></i></li>
                                </a>
                                <a href="https://www.linkedin.com/company/quiin-abenakyo-foundation"  target="blank">
                                    <li><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                                </a>
                                <a href="https://instagram.com/quiin-abenakyo-foundation"  target="blank">
                                   <li><i className="fa fa-instagram" aria-hidden="true"></i></li>
                                </a>
                                <a href="https://youtube.com/quiin-abenakyo-foundation"  target="blank">
                                   <li><i className="fa fa-youtube" aria-hidden="true"></i></li>
                                </a>
                               
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
            </React.Fragment>
        )
    }
}
export default Topnav