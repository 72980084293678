import React, { Component } from "react";
import "./index.css";

export default class index extends Component {
  render() {
    return (
      <section className="twelve columns page_content" style={{paddingBottom:'100px'}}>
        <div className="container ">
          <h2>Who we are</h2>
          <div className="row">
          <div className="six columns">
          <iframe width="100%" height="305px" title="video09" src="https://www.youtube.com/embed/rKc0XVHq9V4" frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="six columns">
            <p>
            Officially launched by H.E Yoweri K Museveni at Sheraton Hotel Kampala, the Quiin Abenakyo Foundation (QAF) is a
             Nonprofit Organization that was founded by Miss Uganda / Miss World Africa 2018-2019, Quiin Abenakyo. </p>
            <p>The organization advocates for women and girls rights by providing holistic interventions that strive to meet
               the needs of vulnerable women and girls, especially those from poor rural areas and disadvantaged group settings. 
               We work with in and out of school girls and women living in slums.
            </p>

            
          </div>
          </div>
          <div className="twelve columns">
          <div className="six columns">
            <h4>Objectives</h4>
            <ol>
              <li>To help children realise and safeguard their rights while strengthening community activism towards promotion and protection of child rights.</li>
              <li>To promote access to SRHR education and services to young people in the home, school and community</li>
              <li>To contribute to increased participation and completion of formal education for girls, and create non formal education programs for out of school girls.</li>
              <li>To increase access to clean water, proper hygiene and sanitation in schools</li>
              <li>To increase the participation of young women and girls participation in political processes and enhance their leadership capacity</li>
            </ol>
            </div>
            <div className="six columns">
              <br/>
            <h4>Our mission</h4> 
                   <blockquote><p>To  empower young people with information skills and tools to enable them live up to their full lives potential</p></blockquote>
            <h4>Our vision</h4> 
            <blockquote><p>A self reliant generation of women and girls</p></blockquote>
            </div>
            

          </div>
          
        </div>
      </section>
    );
  }
}
