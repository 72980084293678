import React, { Component } from 'react'

export default class index extends Component {
    render() {
        return (
            <section className="twelve columns page_content">
                <div className="container ">
                    <h2>About Quiin Abenakyo</h2>
                    <p>Quiin Abenakyo is the pageant holder for Miss Uganda 2018  and Miss World Africa 2018, an achievement that made her the first Ugandan to place this high in the annual global pageant. During the Miss World competition, her Beauty with a Purpose project pitch “Fighting Teenage pregnancies and child marriages” made her one of the winners of the head to head challenge. Following her passion to address women and girls issues, she Founded the Quiin Abenakyo Foundation.
</p>
<p>Quiin also took part in the United Nations Population Fund (UNFPA) “Live your dream” Campaign project addressing Gender Based Violence, child marriage and teenage pregnancies in Adjumani, Gulu,  Mayuge, Yumbe and Moyo. She has further participated in a number of panel discussions such as Africa Now, CommonWealth Parliamentary Conference, High Level Women’s Infrastructure Summit 2020 where she has presented her views in relation to her work in women and girls empowerment.</p>
<p>Her exceptional performance has also seen her earn special recognitions such as National Medalist on Women’s Day 2019,  the Lifetime Achievement Award from Twegaite International, Achievement Award from Ugandan Diaspora Social Networking Gala and Rotary International, and selected as a Paul Harris Fellow.</p>
                </div>
            </section>
        )
    }
}
