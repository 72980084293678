import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Actions from "../../Componets/action/index"


export default class water_and_sanitation extends Component {

  componentDidMount() {
    document.title = 'Water and Sanitation | Quiin Abenakyo Foundation'
}
    render() {
        return (
            <div>
            <Header />
            <section className="content">
              <Intro bgimg="/images/water_full.jpg" tag="Clean water alone can reduce water related deaths by 21%"/>
              <MenuList />
              <section className="twelve columns page_content">
               <div className="container ">
                <h2>Water and Sanitation</h2>

              <p>Access to safe drinking water, good sanitation and hygiene education is known as a major challenge in many
                schools in Uganda. Many schools lack hand washing facilities, enough latrines, poor water and solid waste 
                disposal, and lack of safe drinking water drinking facilities. </p>
                <p>According to the Ministry of Water and Environment sector performance report
(2017) and the Ministry of Education and Sports’ School WASH mapping (2016), the situation of WASH in Schools in Uganda is not meeting Uganda’s national
standards.</p> 
               <p>The QAF therefore aims at improving the water, sanitation and hygiene standards in schools through the following contributions;</p>
              <ol>
                <li>Construction of safe water facilities such as boreholes and installation of piped water facilities.</li>
                <li>Provision of water tanks to schools</li>
                <li>Working with partners who can provide WASH facilities</li>
                <li>Construction of toilets / pit latrines especially separate toilets for girls</li>
                <li>Providing personal hygiene education to students in schools</li>
                <li>Training schools to ensure proper waste management to collect and dispose waste safely</li>
              </ol>
              </div>
              </section>
            </section>
            <Actions />
            <Footer />
        </div>
        )
    }
}
