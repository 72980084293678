import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Board from "../../Componets/board/index"


export default class index extends Component {

    componentDidMount() {
        document.title = 'Board Members | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
                 <Header />
                <section className="content">
                <Intro bgimg="/images/QAF_president_with_the_queen.jpg" tag="Come and join us as we celebrate the girlchild" />
                <MenuList />                   
                    <Board />
                </section>
                <Footer />
            </div>
        )
    }
}
