import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class nataliey extends Component {

    componentDidMount() {
         document.title = 'Dr. Nataliey Bitature | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/nataliey.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">Dr. NATALIEY BITATURE</h6>
                            <span className="position">Board Member</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
                <p>Nataliey is the Chief of Staff at Simba Group of companies, Founder of HER, Co-Founder Tateru Properties and Handymen. She graduated with a&nbsp; Degree in Business Management and Education Studies from Keele University,a Master&rsquo;s in Social Entrepreneurship at Hult International Business School and a Honorary Doctorate from Keele University.</p>
                <p>&nbsp;Furthermore, she is the proprietor and Co-Founder of the award-winning Musana Carts, the solar street vending revolution, which provides urban street vendors with a cleaner, safer and more efficient solution to their cooking, storage and financing needs.</p>
                <p>Naaliey was Named Forbes 30 under 30, named among the top 10 innovators in Africa by the World Economic Forum and was a top contender for a US $1 million prize in the Hultz prize competition. She also won a Young Achievers Award in 2018 for the Business category.</p>

              </div>
            </div>
          </section>
        </section>
        <Footer />
        </div>
        )
    }
}
