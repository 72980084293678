import React, { Component } from 'react'
import  Header  from '../Componets/header'
import Footer from '../Componets/footer/footer'
import Intro from '../Componets/page_intro/page_intro'
import MenuList from '../Componets/menu_list'

export default class index extends Component {

    componentDidMount() {
        document.title = 'Stories | Quiin Abenakyo Foundation'
    }

    render() {
        return (
            <div>
            <Header />
            <section className="content">
            <Intro bgimg="/images/QAF_stories.jpg" tag="stories that really inspire us" />
            <MenuList />
              <section className="twelve columns page_content">
               <div className="container ">
                <h2>Stories</h2>
                <p>Here are some of the stories that really inspire us</p>
               </div>
              </section>
            </section>
            <Footer />
        </div>
        )
    }
}
