import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Action from '../../Componets/action/index'

export default class reproductive_health extends Component {

  componentDidMount() {
    document.title = 'Sexual Reproductive Health and Rights | Quiin Abenakyo Foundation'
}
    render() {
        return (
            <div>
            <Header />
            <section className="content">
              <Intro bgimg="/images/srh_full.jpg" tag="Ensure access to quality sexual and reproductive health services and information"/>
              <MenuList />
              <section className="twelve columns page_content">
               <div className="container ">
                <h2>Sexual Reproductive Health and Rights</h2>

              <p>The QAF team passionately works on raising awareness on the sexual reproductive health needs and rights of young people, designing and delivering age appropriate SRHR services that meet their needs and wants. According to UNFPA, “Uganda's young population is 52% of the population and this is the age that is most affected by HIV/AIDs.  The high risk sexually active women account for 16% of the youth, while high risk sexually active men account for 36%. Related to these behavioural challenges are unwanted pregnancies, sexually transmitted infections and cross-generational sex that are grossly exploitative, especially for the girl child”
With this background, QAF aims at providing access to knowledge and SRH services in the school and community.              </p>
      <p>Our SRH focus areas are;</p>   
      <ol>
        <li>Family Planning</li>
        <li>Menstrual Health Management</li>
        <li>SRH Education</li>
        <li>HIV & AIDS</li>
      </ol>
<h3>Our approach:</h3>
<ol>
  <li>Promoting age appropriate SRH education in schools and communities through clubs</li>
  <li>Linking young people to SRH services through a referral system</li>
  <li>Partnering with medical centers to provide SRH services such as family planning, HIV/AIDS, </li>
  <li>Provide free one on one and group counseling services</li>
  <li>Working with relevant partners to improve SRH service delivery to young people especially in slums.</li>
</ol>
              </div>
              </section>
            </section>
            <Action />
            <Footer />
        </div>
        )
    }
}
