import React, { Component } from "react";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.css";

import "./rotator.css";

export default class rotator extends Component {
  render() {
    return (
      <section className="twelve columns rotator">
          <div className="descrip twelve columns ">
              <div className=" eight columns offset-by-two ">
                <h2>ENRICH.EMPOWER</h2>
                <p>
                  Our goal is to empower young people with information skills and tools to enable them live up to their full lives potential 
                </p>
              </div>
            </div>
          <AliceCarousel 
                autoPlay 
                autoPlayInterval="3000" 
                ease 
                none 
                animationDuration="1000" 
                animationType="fadeout" 
                infinite="true">
            <img src="./images/front1.jpg" className="sliderimg" alt="" />
            <img src="./images/front2.jpg" className="sliderimg" alt="" />
            <img src="./images/front3.jpg" className="sliderimg" alt="" />
           
        </AliceCarousel>
      </section>
    );
  }
}
