import React, { Component } from 'react'
import { Link } from "react-router-dom";

import speaker from './speaker.jpg'
import quiin from './quiin.jpg'
import vincent from './vincent.jpg'
import nataliey from './nataliey.jpg'
import namboka from './namboka.jpg'
import omamteker from './omamteker.jpg'
import prosper from './prosper.jpg'

import './index.css'

export default class index extends Component {
    render() {
        return (
            <section className="twelve columns board" >
            <div className="container">
                <div className="row">
                    <h2>Board of Directors</h2>
                    <div className="three columns members" style={{marginLeft:'0'}}>
                    <Link to="/hon_rebecca_kadaga">
                        <span className="member-pic">
                            <img src={speaker} alt="" width="100%" />
                        </span></Link>
                        <span className="member-name">
                        <Link to="/hon_rebecca_kadaga">Rt. Hon Rebecca Kadaga</Link>
                        </span>
                        <span className="member-title">
                            Patron
                        </span>
                    </div>
                    <div className="three columns members">
                    <Link to="/about_quiin_abenakyo">
                        <span className="member-pic">
                            <img src={ quiin } alt="" width="100%" />
                        </span></Link>
                        <span className="member-name">
                        <Link to="/about_quiin_abenakyo">MISS QUIIN ABENAKYO</Link>
                        </span>
                        <span className="member-title">
                        Founder and Executive Director
                        </span>

                    </div>
                    <div className="three columns members">
                    <Link to="/vincent_oriedo">
                        <span className="member-pic">
                            <img src={vincent} alt="" width="100%" />
                        </span>
                        </Link>
                        <span className="member-name">
                        <Link to="/vincent_oriedo">Dr. Vincent Oriedo</Link>
                        </span>
                        <span className="member-title">
                        Board Member
                        </span>

                    </div>
                    <div className="three columns members">
                       <Link to="/nataliey_bitature">
                        <span className="member-pic">
                            <img src={nataliey} alt="" width="100%" />
                        </span></Link>
                        <span className="member-name">
                        <Link to="/nataliey_bitature">Dr. NATALIEY BITATURE</Link>
                        </span>
                        <span className="member-title">
                        Board Member
                        </span>

                    </div>
                   
                    <div className="three columns members offset-by-one">
                        <Link to="/ester_nambooka">
                        <span className="member-pic">
                            <img src={namboka} alt="" width="100%" />
                        </span>
                        </Link>
                        <span className="member-name">
                        <Link to="/ester_nambooka">ESTHER NAMBOOKA</Link>
                        </span>
                        <span className="member-title">
                        Board Member
                        </span>

                    </div>
                   
                    <div className="three columns members">
                        <Link to="/michelle_omamtecker">
                        <span className="member-pic">
                            <img src={omamteker} alt="" width="100%" />
                        </span>
                        </Link>
                        <span className="member-name">
                        <Link to="/michelle_omamtecker">MICHELLE OMAMTECKER</Link>
                        </span>
                        <span className="member-title">
                        Board Member
                        </span>
                    </div>
                    <div className="three columns members">
                        <Link to="/prosper_ahimibisibwe">
                        <span className="member-pic">
                            <img src={prosper} alt="" width="100%" />
                        </span>
                        </Link>
                        <span className="member-name">
                        <Link to="/prosper_ahimibisibwe">Dr. PROSPER AHIMIBISIBWE</Link>
                        </span>
                        <span className="member-title">
                         Board Member
                        </span>
                    </div>

                </div>
            </div>

            </section>
        )
    }
}
