import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./footer.css";

export default class footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="three columns foot">
              <h4>About Us</h4>
              <ul>
                <Link to="/about_quiin_abenakyo">
                  <li>About Quiin Abenakyo</li>
                </Link>
                <Link to="/who_we_are">
                  <li>Who we are</li>
                </Link>
                <Link to="/our_approach">
                  <li>Our Approach</li>
                </Link>
                <Link to="/our_staff">
                  <li>Our Staff</li>
                </Link>
              </ul>
            </div>
            <div className="three columns foot">
              <h4>What We Do</h4>
              <ul>
                <Link to="/child_rights_and_protection">
                  <li>Child rights & protection</li>
                </Link>
                <Link to="/sexual_reproductive_health_and_rights">
                  <li>Sexual Reproductive Health & Rights</li>
                </Link>
                <Link to="/education">
                  <li>Education:Formal & Informal</li>
                </Link>
                <Link to="/water,_Sanitation_and_hygiene">
                  <li>Water, sanitation & hygiene</li>
                </Link>
                <Link to="/leadership_and_governance">
                  <li>Leadership & Governance</li>
                </Link>
              </ul>
            </div>
            <div className="three columns foot">
              <h4>Get Involved</h4>
              <ul>
                <Link to="/">
                  <li>Shop</li>
                </Link>
                <Link to="/volunteer">
                  <li>Volunteer</li>
                </Link>
                <Link to="/">
                  <li>Contact</li>
                </Link>
                <Link to="/donate">
                  <li>Donate</li>
                </Link>
              </ul>
            </div>
            <div className="three columns foot">
              <h4>GET IN TOUCH</h4>
              <ul>
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i> +256 758214777
                </li>
                <li>
                  {" "}
                  <i className="fa fa-phone" aria-hidden="true"></i> +256 788535115
                </li>
                <li>
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  admin@abenakyofoundation.org
                </li>
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>House No 1,
                  Bukasa 4th Close
                  <br />
                  <span> Namuwongo kampala Uganda</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <ul className="foot_social four columns offset-by-four">
              <a
                href="https://www.facebook.com/abenakyofoundation/"
                target="blank"
              >
                <li>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </li>
              </a>
              <a href="https://twitter.com/QAFoundation1" target="blank">
                <li>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </li>
              </a>
              <a
                href="https://www.linkedin.com/company/quiin-abenakyo-foundation"
                target="blank"
              >
                <li>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </li>
              </a>
              <a
                href="https://instagram.com/quiin-abenakyo-foundation"
                target="blank"
              >
                <li>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </li>
              </a>
              <a
                href="https://youtube.com/quiin-abenakyo-foundation"
                target="blank"
              >
                <li>
                  <i className="fa fa-youtube" aria-hidden="true"></i>
                </li>
              </a>
            </ul>
          </div>
          <div className="row">
            <p className="copyright">
              <span>abenakyofoundation.org</span> - Copyright 2020.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
