import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class vincent extends Component {

  componentDidMount() {
    document.title = 'Dr. Vincent Oriedo | Quiin Abenakyo Foundation'
  }
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/vincent.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">Dr. Vincent Oriedo</h6>
                            <span className="position">Board Member</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
              <p>Dr. j. Vincent B. Oriedo (D.O.B. 01 may 1959) is a biotechnology research an d development scientist, inventor, author, executive global technology &amp; people leader, and philanthropy[ist of eastern Africa descent, and based in the united states of America(USA). Dr. oriedo is a thirty year plus veteran of the Dow chemical company (TDCC) &ndash; an American multinational chemical corporation with a presence in about 160countries headquartered in midland, Michigan, united states, and a subsidiary of Dow Inc.-where he has worked in a variety of interdisciplinary and multidisciplinary technical and people leadership roles across diverse functions/businesses and geographies. His TDCC professional professional apogees entail roles as a member of steering team that successfully implemented a new dow globalization strategic and operational paradigm shift from soloed site-based business model to fully leveraged global business units (GBU) paradigm; development and implementation of an empowering people&rsquo;s strategy with key elements as Employee Development Planning (EDP) and inclusion &amp; diversity as a business imperative, and successive enactment of Affinity Groups. He is a recipient of numerous internal TDCC and external (national and international/global) recognitions and awards: including the United States&rsquo; national science foundation (NSF); the Russian Academy of Sciences (RAS); the Max Planck Society, Germany; American Chemical Society (promoting Inclusion &amp; Diversity in the workplace); etc.</p>

<p>Business Enterprise Leadership: Dr. Oriedo has directed multipartite international global business and scientific collaborations-in the united states of America, Europe (European union states), Canada, the state of Israel, the Russian federation, and other former soviet union socialist republics(USSR)/ the common wealth of independent states(CIS)-supporting basic and applied research and technology development &amp; transfer, and commercialization projects under the auspices of international science and technology Centre(ISTC). He has steered a tripartite corporate biotechnology business alliance &ndash;a strategic partnership between the Dow chemical companies. Dow Agro sciences, and large scale biology corporation (LSBC). Moreover, he spearheaded development of: (1) a full-fledged cGMP bioprocessing commercial manufacturing &gt;100,000-litre fermentation facility for production &amp; distribution of Anticholesteremic biopharmaceutical intermediate product; and (2) cGMP bioprocessing commercial manufacturing facility production &amp; distribution biopharmaceutical chiral intermediate anti-HIV drug.</p>

<p>Science &amp; technology: research and development scientist in multidisciplinary industrial &amp; academic research; inventor with multiple biotechnology and genomic/genetic engineering U.S &amp; transnational patents; Author of a myriad of multidisciplinary and&nbsp;&nbsp; interdisciplinary international and intra-TDCC scientific scholarly &amp; business publications &amp; presentation; Executive Global Corporate People &amp; Technical Resource Leadership-intellectual &amp; technological resource development &amp; strategic planning; and directing multimillion-dollar global &amp; regional transnational strategic alliances/business ventures; interdisciplinary technical adroitness spanning biotechnology biomed, genetic engineering/genomics, bio analytical &amp; bioinformatics, biopharma &amp; biosafety-analytical sciences, production process research &amp; scaling, and chemical manufacturing-product development &amp; production and quality control &amp; assurance- and gas-phase ion chemistry; integrated governance, enterprise risk management, &amp; compliance; certification and licensure in modalities that include Design for Six Sigma Master Black Belt, Lean &amp; Six Sigma Master Black Belt, and Certified Master RCA-CA, with a Global Strategic Resource Development Leadership acumen.</p>

<p>Professional Executive Leadership: highlights include progressive management implementation planning; leading a multifunctional global organization, developing and implementing empowering people strategy comprising employee development, transfer and commercialization-collaborations in the U.S, Europe, Israel, &amp; Russia; strategic partnerships and relationships with academia, governmental, industrial/corporate, national laboratories, NGO entities; directing cross-business implementation of regulatory requirements, discerning/establishing &amp; implementing corporate EH&amp;S biotech requirements, and preventing multimillion-dollar manufacturing loss-effecting alternate compliance solution.</p>

<p>Faith &amp; philanthropy:&nbsp; Dr. Oriedo is a Presbyterian [PC(USA)] ordained ruling elder and serves on the session council of the Midland, Michigan based the Bertha E. R. Strosacker Memorial Presbyterian Church(MPC); he chairs the ministry of growing leaders workgroup [sic] tasked with leadership development at all levels in the life of MPC community. He previously served on the Mission Interpretation Community and its successor the Reaching Out Regionally and Globally Workgroup tasked with regional and global missional initiatives at the hear of the congregation, leading MPC as it discerns and engages in the active life of faith; using its resources, financial and talent, to serve human and spiritual needs of those in our communities (local, regional and global). He was a founding member of the task force on &ldquo;Save Our Situation&rdquo; MPC missional initiative in Uganda&rsquo;s Ikamiro Village in Muko sub-county; spearheaded by Sue and John Waechter. The Muko initiative is the predecessor to the Agape Community Transformation (ACT Uganda &amp; ACT U.S.) Organization-an ecumenical Christian partnership of two organizations, one based in Uganda and the USA, that are open to working with all people to enhance the spiritual, physical, economic and societal conditions in Uganda. He is the founding CEO and President of Blasio Na Wazazi Memorial Community Based Organization and Foundation.</p>

<p>Business/Professional Couching &amp; Mentoring: Dr. Oriedo is an expert business and vocational coach and mentor. He focuses on young ad midlevel professionals for short-term situational leadership performance-driven expectation (coachin) or strategic/long term leadership competency development (mentoring) skilling and/or up skilling.</p>

<p>Boards &amp; Advisory Committees and Consulting; Dr. Oriedo has served on several boards and advisory committees and consulting at various organizations including Dow Chemical; Delta College Michigan; University of Michigan Health System; MidMichigan Health System at Ann Arbor in Michigan; American Chemical Society (ACS); American Society of Mass Spectrometry(ASMS); Texas A&amp;M University al College Station in Texas; etc. Dr. Oriedo is a &ldquo;patient-caregiver&rdquo; advisor and a member of the steering committee of the integrated Michigan Patient-centered Alliance in care Transitions (I-MPACT) &ndash;a Blue Cross Blue Shield of Michigan&rsquo;s first patient-focused collaborative; a Collaborative Quality Initiative(CQI) that works to improve quality and reduce variation in care transition practices among physicians and hospitals.</p>

<p>Leadership Philosophy: A leadership philosophy innately predicted on integrity, humanity, partnership, affirmation, empowerment, incessant learning, respect and accountability. My principle goal is winning the respect and trust of my team by providing visionary &amp; inspirational leadership, building relationship with people-that which I do with them, and not to them; sharing the big picture &amp; getting everyone to the place we are supposed to go; empowering people to think for themselves-letting people know they are appreciated.</p>

            

              </div>
            </div>
          </section>
        </section>
        <Footer />
        </div>
        )
    }
}
