import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class prosper extends Component {

  componentDidMount() {
    document.title = 'Dr. Prosper Ahimibisibwe | Quiin Abenakyo Foundation'
}
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/prosper.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">Dr. PROSPER AHIMIBISIBWE</h6>
                            <span className="position">Board Member</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
              <p>Prosper is the Director and Chief of Operations at M-Scan, and a Medical Officer at the Ministry Of Health. He graduated with a Bachelor Of Medicine from Makerere University Kampala. He is a medical doctor with career interests in medical innovation, health economics, research and global surgery.</p>
              <p>Prosper is the Founder of M-Scan, a low-cost innovation that integrates IT with power of ultrasound on a mobile handheld device to scan pregnant mothers in rural Uganda. He is also the Chief Executive Officer at Kangaroo Plus Uganda which develops neonatal jackets that protect preterm babies against low body temperatures.</p>
              <p>Prosper was Selected to be part of the COVID-19 clinical and case management, a Global Shaper under the World Economic Forum, YELP fellow at the L&eacute;O Africa Institute, and Guild Representative on Council (GRC) at Makerere University.</p>

              </div>
            </div>
          </section>
        </section>
        <Footer />
        </div>
        )
    }
}
