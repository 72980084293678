import React, { Component } from 'react';
import  Topnav  from "./Top-nav/topnav"
import  Bottom  from "./Bottom-nav/Bottom"
import './index.css'

export default class index extends Component {

    state={
        scrolled:false
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
      }
    
      componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }
handleScroll=()=>{
    if (window.scrollY < 120) {
        this.setState({ scrolled: false });
      } else {
        this.setState({ scrolled: true });
      }
}
    render() {
        console.log(this.state)
        return (
            <header>
              <Topnav scrolled={this.state.scrolled} />
              <Bottom scrolled={this.state.scrolled} />
            </header>
        )
    }
}
