import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Action from '../../Componets/action/index'

export default class education extends Component {

  componentDidMount() {
    document.title = 'Education: Formal and informal | Quiin Abenakyo Foundation'
}
    render() {
        return (
            <div>
            <Header />
            <section className="content">
              <Intro bgimg="/images/education_full.jpg" tag="An investment in knowledge pays the best interest"/>
              <MenuList />
              <section className="twelve columns page_content">
               <div className="container ">
                <h2>Education: Formal and informal</h2>

              <p>Education is an essential solution in reducing poverty in Uganda.  Girls who get an education are less likely to get married young, and will have a more productive life and better future. Much as the Government of Uganda introduced the Universal Primary & Secondary Education programs that provide free education, many children particularly girls continue to drop out of school due to a number of contributing factors like extreme poverty, cultural norms and practices that do not believe in girls education, early marriage, teenage pregnancies, abuse, neglect among others.</p>
              <p>According to the Uganda National Examination Board (UNEB) figures,” in just two decades, about 12.2 million pupils started primary one but only 6.95 million (57.2 per cent) completed their primary schooling cycles – over 5.2 million (42.8 percent) dropped out. This means that at least 43 out of every 100 (or four out of every 10) pupils, who started school, dropped out before completing Primary 7.”</p>
              <p>While the government continues to advance formal education, informal education continues to lack, which would otherwise have been an alternative to provide them with the necessary skills and confidence for employment, hence influencing a brighter future for them. </p>
              <p>In order to contribute to the progress of both the formal and Informal education, the QAF  has the conducts the following interventions;</p>
              <ol>
                <li>Providing practical skill development trainings</li>
                <li>Provide business trainings for our of school girls</li>
                <li>Providing ICT trainings for out of school girls</li>
                <li>Creating and providing sponsorship packages to encourage return to school</li>
                <li>Providing learning equipment at the QAF offices </li>
                <li>Encouraging and enhancing creativity and innovation</li>
                <li>Establishing school clubs to encourage girls to stay in school</li>
                <li>Parents engagement programs to emphasize education for their children</li>
                <li>Providing scholastic materials for those living in extreme poverty.</li>
              </ol>
              </div>
              </section>
            </section>
            <Action />
            <Footer />
        </div>
        )
    }
}
