import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class michelle extends Component {

  componentDidMount() {
    document.title = 'Michelle Omamtecker | Quiin Abenakyo Foundation'
  }
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/omamteker.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">MICHELLE OMAMTECKER</h6>
                            <span className="position">Board Member</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
              <p>Michelle is the Founder and Director Malengo Foundation and&nbsp; Director of Cambridge school in Entebbe. She graduated with a Degree in Business Administration from Nelson Mandela Metropolitan University. She&nbsp; founded the Malengo Foundation, an organization which advocates for disability rights and social inclusion. Malengo Foundation held Uganda&rsquo;s first fashion show inspired by the United Nations Sustainable Development Goals agenda with specific reference to Global Goal 10 for Reduced Inequalities took place with models across various abilities on the runway.The Malengo Market also sells and promotes items, such as clothes, jewellery, and bags on behalf of persons with disabilities. She was also featured in&nbsp; a CNN documentary in relation to her work with people with disabilities.</p>
               <p>Michelle&nbsp; won a humanitarian award in 2017 from the PAFA awards, selected GGWcup EA 2020 Ambassador Goal 10, and was also nominated among the 20 most influential people in Entebbe municipality in 2017.</p>

              </div>
            </div>
          </section>
        </section>
        <Footer />
        </div>
        )
    }
}
