import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class projects extends Component {

    componentDidMount() {
        document.title = 'Projects | Quiin Abenakyo Foundation'
    }

    render() {
        return (
            <div>
            <Header />
            <section className="content">
            <Intro bgimg="/images/img_project.jpg" tag="Projects"/>

            <section className="twelve columns page_content">
        <div className="container ">
          <h2>We still have a dream</h2>
            <p>Due to the closure of schools, students are now back home with a lot of time on their hands which if not used wisely can be  detrimental physically, psychologically and socially. 
At the beginning of June 2020, we created a safe space at our office premises where we host about 25-30 young people from the age of 13-18 years for weekly sessions that focus on a wide range of topics and activities . Some of the topics and activities include; Children’s Rights, Gender Based Violence, Personal Safety, Public Speaking, Personal Development and Reading clubs.
Our participants are  from the Kanyogoga Slum in Namuwongo, Kampala which is characterized by poverty, violence, poor sanitation, and crime gangs. With these engagements, we hope to keep the young people engaged, empowered, encouraged and informed during this crisis.
 </p>        </div>
         </section>
            </section>
            <Footer />
        </div>
        )
    }
}
