import React, { Component } from 'react'
import { Link } from "react-router-dom";
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import  MenuList  from "../../Componets/menu_list/index"
import './team.css'

export default class our_staff extends Component {

    componentDidMount() {
        document.title = 'Our Staff | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
                <Intro bgimg="/images/staff.png" tag=" Meet the innovative team" />
                <MenuList />
                <section className="twelve columns what_we_do" style={{paddingBottom:'100px'}}>
                    <div className="container ">
                        <div className="row">
                        <h2 align="center">Our Staff</h2>
                        <section className="columns staff">

                            <div className="image">
                                <img src="/images/quiin.jpg" alt="" width="100%" />
                            </div>
                           <Link to="/about_quiin_abenakyo"><h6 className="name">MISS QUIIN ABENAKYO</h6></Link>
                            <span className="position">
                            Founder and Executive Director
                            </span>
                        </section>
                        <section className="columns staff">
                            <div className="image">
                                <img src="/images/christine.jpg" alt="" width="100%" />
                            </div>
                            <Link to="/staff/christine_adero"><h6 className="name">Ms CHRISTINE ADERO</h6></Link>
                            <span className="position">
                            programs manager
                            </span>
                        </section>
                        <section className="columns staff">
                            <div className="image">
                                <img src="/images/ritah.jpg" alt="" width="100%" />
                            </div>
                            <Link to="/staff/abasasira_ritah"><h6 className="name">Ms ABASASIRA RITAH</h6></Link>
                            <span className="position">
                            Administrator
                            </span>
                        </section>
                        <section className="columns staff">
                            <div className="image">
                                <img src="/images/okeny.jpg" alt="" width="100%" />
                            </div>
                            <Link to="/staff/okeny_patrick"><h6 className="name">Mr Okeny Patrick</h6></Link>
                            <span className="position">
                            IT Support
                            </span>
                        </section>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
        )
    }
}
