import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import  MenuList  from "../../Componets/menu_list/index"
import  Quiin  from "../../Componets/quiin_abenakyo"
import Action from '../../Componets/action/index'


export default class index extends Component {

    componentDidMount() {
        document.title = 'About Quiin Abenakyo | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
                <Intro bgimg="/images/quiin_abenakyo.jpg" tag="Quiin Abenakyo" />
                <MenuList />
                <Quiin />
                <Action />
              
            </section>
            <Footer />
        </div>
        )
    }
}
