import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'

import './map.css'

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)

const Map = ({ location, zoomLevel }) => (
  <section className="twelve columns map" style={{height:"350px"}}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCK6M79qZsGKFLNnu7L3q_uUYe_2_u3hJI' }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={0.3032744}
          lng={32.6157071}
          text={"Quiin Abenakyo Foundation"}
        />
      </GoogleMapReact>
  </section>
)

export default Map
