import React, { Component } from 'react'
import './index.css'

export default class WhatWeDo extends Component {
    render() {
        return (
            <section className="twelve columns what_we_do">
                <div className="container ">
                    <div className="row">
                    <h2>What We Do</h2>
                    <p>Officially launched by H.E Yoweri K Museveni at Sheraton Hotel kampala, the Quiin Abenakyo Foundation (QAF) is a Nonprofit Organization that focuses on addressing issues on Child Marriages and Teenage Pregnancy through providing holistic interventions that strive to meet the needs of vulnerable adolescent girls especially those from poor rural areas and disadvantaged group settings through Advocacy, education and skilling, Sexual Reproductive Health Rights, Community Mobilization and Sensitization.</p>
                   <h3>Our mission</h3> 
                   <blockquote><p>To  empower young people with information skills and tools to enable them live up to their full lives potential</p></blockquote>
                   <br/>
                   </div>
                    <div className="row">
                        <div className="three columns foundation">
                            <img src="./images/f1 (1).png" alt="" />
                            <h3>Child Rights And Protection</h3>
                            <p>Child Rights and protection is one of the most underlooked issues in Uganda. Many children are oblivious of their rights and may suffer in silence. According to UNICEF ...  </p>
                            <a href="/child_rights_and_protection">Learn More</a>
                        </div>
                        <div className="three columns foundation">
                        <img src="./images/f1 (2).png" alt="" />
                        <h3>Sexual Reproductive Health and Rights</h3>
                        <p>The QAF team passionately works on raising awareness on the sexual reproductive health needs and rights of young people, designing and delivering age appropriate... </p>
                        <a href="/sexual_reproductive_health_and_rights">Learn More</a>
                        </div>
                        <div className="three columns foundation">
                        <img src="./images/f1 (3).png" alt="" />
                        <h3>Education: Formal and informal</h3>
                        <p>Education is an essential solution in reducing poverty in Uganda. Girls who get an education are less likely to get married young, and will have a more productive life and... </p>
                        <a href="/education">Learn More</a>
                        </div>
                        <div className="three columns foundation">
                        <img src="./images/governance.jpg" alt="" />
                        <h3> Leadership and governance</h3>
                        <p>Participation of women and girls in the electoral processes and leadership positions remains low. For example, as of June 2016, Uganda had only 28 women Ministers out of...  </p>
                        <a href="/water,_Sanitation_and_hygiene">Learn More</a>
                        </div>
                       
                       
                    </div>
                </div>
            </section>
        )
    }
}
