import React, { Component } from 'react'
import './joinus.css'

export default class Joinus extends Component {
    render() {
        return (
            <section className="join-us">
                <div className="container">
                <div className="row">
                  <div className="seven columns vid offset-by-two">
          <iframe width="100%" height="355px" className="cinema" title="video09" src="https://www.youtube.com/embed/rKc0XVHq9V4" frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
                </div>
                </div>
            </section>
        )
    }
}
