import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'

export default class nambooka extends Component { 
  
  componentDidMount() {
  document.title = 'Ester Nambooka | Quiin Abenakyo Foundation'
  }
    
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/namboka.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">ESTHER NAMBOOKA</h6>
                            <span className="position">Board Member</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
                <p>Esther Namboka is the Founder and Executive Director at Gals Forum International. She graduated with a Degree in Procurement and Supply Chain Management from Uganda Management Institute&nbsp; and a Bachelor&rsquo;s Degree in Information Management from Makerere University Kampala.</p>
                <p>She Founded, Gals Forum International, an NGO that fosters stable and economically empowered families with focus on women and girls empowerment. Through her work, she has been an advocate for harmonious families, women and girls activists, mentor and a certified financial literacy trainer.</p>
                <p>Esther was also the chairperson, and Alumni Of the Young African Leaders Initiative (YALI), an Initiative by President Barack Obama. In addition, She has also&nbsp; been recognized as the World Bank social Inclusion Hero in Uganda, and ranked Top 10 in Africa for skilling 569 women vendors and a finalist, Top 40 under 40 for serving vulnerable communities especially women and girls.</p>

              </div>
            </div>
          </section>
        </section>
        <Footer />
        </div>
        )
    }
}
