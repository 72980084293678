import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import axios from 'axios'
import './vol.css'

export default class Volunteer extends Component {

    state = {
        fullname: "",
        dateofbirth: "",
        email: "",
        nationality: "",
        about_your_self: "",
        vol_timeframe: "",
        reasons:"",
        find_us:"",
        
        form_submit: false,
        form_message: "",
        submitText:"Volunteer"
    }

    componentDidMount() {
        document.title = 'Volunteer with us | Quiin Abenakyo Foundation'
    }


    handleChange =(e)=> this.setState({[e.target.name]: e.target.value})
    onSubmit =(e) =>{
        e.preventDefault()
        this.setState({submitText: "Sending..."})
        axios({
            method: 'POST',
            url: 'https://abenakyofoundation.org/public/',
            data: {
                fullname: this.state.fullname,
                dateofbirth: this.state.dateofbirth,
                email: this.state.email,
                nationality: this.state.nationality,
                about_your_self: this.state.about_your_self,
                vol_timeframe: this.state.vol_timeframe,
                reasons: this.state.reasons,
                find_us: this.state.find_us
            }
        })
        .then(resp => {
            this.setState({form_submit: resp.data.success, form_message: resp.data.message})
            this.setState({submitText: "Volunteer"})
        });

        this.setState({
            fullname: "",
            dateofbirth: "",
            email: "",
            nationality: "",
            about_your_self: "",
            vol_timeframe: "",
            reasons:"",
            find_us:"",
            
        })
    }

    getFormStyle(){
        if(this.state.form_submit){
            return {display:"none"}
        }else{
            return {display:"block"}
        }
    }

    getAlert(){
        if(this.state.form_submit){
            return "show"
        }else{
            return "hide"
        }
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
            <Intro bgimg="/images/QAF_volunteer.jpg" tag="Join the movement" />
            <MenuList />
            
               <section className="six columns page_content offset-by-three">
               <div className="container ">
                <h2>Volunteer with us</h2> 
                 <p className={"alert_success twelve columns "+ this.getAlert()}>{this.state.form_message}</p>
                 <form onSubmit={this.onSubmit} style={this.getFormStyle()}>
                 <div className="row">
                   
                    <div className="six columns">
                        <input 
                           type="text" 
                           name="fullname" 
                           required 
                           className="formfield"
                           placeholder="Fullname"
                           value ={this.state.fullname}
                           onChange ={ this.handleChange}
                         />
                    </div>
                    <div className="six columns">
                        <input type="email" 
                               name="email" 
                               required 
                               className="formfield"  
                               placeholder="Email Address" 
                               value={this.state.email}
                               onChange ={this.handleChange}
                               
                               />
                    </div>
                </div>
                <div className="row">
                    <div className="six columns">
                        <input type="text" 
                               name="dateofbirth" 
                               required  
                               className="formfield" 
                               placeholder="Date of birth (21/09/1985)" 
                               value={this.state.dateofbirth}
                               onChange={this.handleChange}
                               
                            />
                    </div>
                    <div className="six columns">
                        <input type="text" 
                               name="nationality" 
                               required  
                               className="formfield" 
                               placeholder="Nationality"
                               value ={this.state.nationality} 
                               onChange ={this.handleChange}
                               
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label>Tell us a bit about yourself</label>
                        <input type="textarea" 
                               style={{height:'100px', width:"102%"}} 
                               required 
                               className="formfield" 
                               name="about_your_self"
                               value={this.state.about_your_self} 
                               onChange ={this.handleChange}
                               
                               />
                    </div>
                </div>
                <div className="row">
                        <input type="text" 
                               name="vol_timeframe" 
                               className="formfield" 
                               required  
                               placeholder="When would you like to volunteer, and for how long?"
                               value ={this.state.vol_timeframe}
                               onChange ={this.handleChange}
                             />
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label>Why have you chosen to volunteer</label>
                        <input type="textarea" 
                               style={{height:'100px', width:"102%"}} 
                               required 
                               className="formfield" 
                               name="reasons"  
                               value ={this.state.reasons}
                               onChange ={this.handleChange}
                            />
                    </div>
                </div>
                <div className="row">
                        <label>How did you hear about us? </label>
                        <select type="text" name="find_us" className="formfield" 
                          onChange ={this.handleChange}
                          value={this.state.find_us}
                        >
                            <option defaultValue="" value="" default="" style={{display:'none'}} disabled="">- select - </option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Google search">Google search</option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Other">Other</option>
                        </select>
                </div>
                <div className="row">
                    <div className="five columns offset-by-four">
                        <input type="submit" className="vol_btn" value={this.state.submitText} name="Volunteer" />
                    </div>
                </div>
                </form>
               </div>
                </section> 

            </section>
            <Footer />
        </div>
        )
    }
}
