import React, { Component } from 'react'

export default class index extends Component {
    render() {
        return (
            <section className="twelve columns page_content">
                <div className="container ">
                <h2>Covid-19 Response</h2>
                <p>On 20th March 2020, following the outbreak of and as a measure to prevent the spread of the CoronaVirus, 
                President Yoweri Museveni directed the closure of all schools and about 15 million school going children were 
                sent back home. With the abrupt closure of schools, most parents were not prepared on how to keep their children 
                engaged and supported. </p>
                <p>Although the Covid-19 pandemic has affected people's lives globally and how  civil societies have been working, we at the Quiin Abenakyo Foundation have derived means to continue reaching out to the young people in order  to continue supporting them and keep them engaged  through different programs and activities. We have been building their capacity by providing them with information and resources that will build on their knowledge and skills, while keeping in mind the COVID-19 guidelines and standard operating procedures. Our activities include information sessions, skills training and providing care packages. Together, we can help young people still take charge of their future. </p>
           
                </div>
            </section>
        )
    }
}
