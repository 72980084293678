import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import WhoWeAre from '../../Componets/who_we_are'
import  MenuList  from "../../Componets/menu_list/index"
import Action from "../../Componets/action/index"


export default class about_QAF extends Component {

    componentDidMount() {
        document.title = 'Who we are | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
                <Intro bgimg="/images/qaf.jpg" tag="together we can make a difference" />
                <MenuList />
                <WhoWeAre />
                <Action />
              
            </section>
            <Footer />
        </div>
        )
    }
}
