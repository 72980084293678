import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'


export default class rebecca extends Component { 
  
  
    componentDidMount() {
      document.title = 'Rt. Hon Rebecca Kadaga | Quiin Abenakyo Foundation'
    }

    
    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the Board Members" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/speaker.jpg" alt="" width="100%" />
                            </div>
                            <h6 className="name">Rt. Hon Rebecca Kadaga</h6>
                            <span className="position">Patron</span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
              <p>Rt.Hon.Rebecca A.Kadaga is the Speaker of Parliament of Uganda, elected for a second term in May 2016-the third position in the hierarchy of National Leadership of Uganda after the President and the Vice President.</p>

<p>She is an Advocate of the Courts of Judicature in Uganda having been enrolled in 1982. She holds a Master&rsquo;s Degree in Law, from University of Zimbabwe (2002 to 2003) and the first woman to establish a law firm in Uganda-Kadaga &amp; Co. Advocates established in 1984.</p>

<p>&nbsp;</p>

<p><strong>Work related experience</strong></p>

<p>Rt.Hon.Kadaga has served as a Legislature for an uninterrupted period of 27years as the Woman Member of Parliament for Kamuli district, since 1989 as National Resistance council member.</p>

<p>In the 27years, she was a back bencher for 7years (1996 to 1998), Minister of State for regional cooperation(1996 to 1998), Minister of State in charge of Transport (1998 to 1999), Cabinet Minister in charge of Parliamentary Affairs(1999 to 2001), Deputy speaker of parliament(2001 to 2011) and speaker from 2011 to date.</p>

<p><strong>National resistance movement party ideologue</strong></p>

<p>She served in National Resistance Movement (the ruling party in Uganda) since 1989 as a Member of the National Resistance Movement Council, from 2005 as the national Resistance Movement women&rsquo;s League Chairperson of Kamuli district, and rose to 2nd Deputy National Vice Chairperson and Member of the Central Executive Committee.</p>

<p><strong>International perspective</strong></p>

<p>Rt.Hon.Kadaga served the Inter-Parliamentary Union as President, Africa Region (October,2014 to 2016) and the Chairperson of Commonwealth Women Parliamentarians(2014 to 2016), having served as the first Vice Chairperson of the same organization(2009 to 2014).</p>

<p>She was an Executive Committee Member of the Inter-Parliamentary Union representing the African Region, and Chairperson Commonwealth Women Parliamentarians (Africa Region). In this fora she campaigned for the women emancipation in politics and governance in many regions of the commonwealth.</p>

<p>Has presented many papers on Women&rsquo;s Rights, Youth Emancipation, Social and Economic Justice.</p>

<p><strong>Awards </strong></p>

<p>First woman in Uganda to hold the office of the speaker and has received awards in appreciation of her work.</p>

<p>In 2013 she received the Golden Jubilee Award by the president of Uganda, while in 2014 she received the Grand Officer of the Republic of Benin from the President of Benin in recognition of her work as women empowerment campaigner.</p>

<p><strong>Other distinguished responsibilities</strong></p>

<ul>
	<li>Secretary General, East African Women Parliamentary Association in 1996</li>
	<li>President of the International Federation of Women Lawyers-FIDA, Uganda in 1986 to 1989, and was credited with establishing the first Legal Aid Service in Uganda under FIDA</li>
	<li>Founding Chairperson, University Council-Mbarara University of Science and Technology in Uganda</li>
	<li>Current Chairperson of Busoga University Council in Uganda</li>
	<li>Campaigner for the eradication of Female Genital Mutilation in Uganda and the Horn of Africa.</li>
	<li>Advocate of Human Rights and Social Justice at the Commission on the status of women, UN panels Pan African Parliament, the East African Region, at the Conference of Women Speakers.</li>
	<li>Chairperson of the IPU-IGAD Women&rsquo;s Caucus.</li>
	<li>Spearheaded the establishment of Certificate of Gender Equity, the first in the world &ndash; recently acknowledged by the UN Women at the International Women&rsquo;s Day at Kololo, Uganda-8th March, 2016.</li>
	<li>First woman to establish a law firm in Uganda</li>
	<li>Patron of the Youth Go Green (Environmental Conservation Group).</li>
	<li>Campaigner for girl child education and for Women&rsquo;s Rights in Uganda and Africa.</li>
	<li>Appointed Vice Chairperson of the Commonwealth Parliamentary Association(2017 to 2018)</li>
	<li>Commonwealth Parliamentary Association President Designate, 2018 to 2019.</li>
</ul>

              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
        )
    }
}
