import React, { Component } from 'react'
import { Link } from "react-router-dom";

import "./index.css"

export default class index extends Component {
    render() {
        return (
            <section className="twelve columns actions" >
                    <div className="one-half column don">
                        <Link to="/donate">
                        <h2>Donate </h2>
                        <p>Lend a hand to help the disadvantaged</p>
                        </Link>
                    </div>
                    <div className="one-half column jon">
                        <Link to="/volunteer">
                        <h2>Volunteer with Us</h2>
                        <p>Join the movement to empower the youth</p>
                        </Link>

                    </div>
            </section>
        )
    }
}
