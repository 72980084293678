import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Action from '../../Componets/action/index'


export default class child_rights extends Component {

    componentDidMount() {
        document.title = 'Child rights and protection | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
                <Intro bgimg="/images/QAF_child_rights.jpg" tag="Girls particularly are at a greater risk of abuse"/>
                <MenuList />
                <section className="twelve columns page_content">
               <div className="container ">
                <h2>child rights and protection</h2>
                <p>Child Rights and protection is one of the most underlooked issues in Uganda. 
                    Many children are oblivious of their rights and may suffer in silence.
                     According to UNICEF Uganda, most children in Uganda have faced some form of violence – physical,
                    sexual, emotional or domestic. More than 8 million children, 51 per cent of the child population, 
                    are believed to be vulnerable.</p>
                <p>Girls particularly are at a greater risk of abuse.</p>
                <div className="row">
                    <article className="six columns">
                <p>Under the Child Rights and protection program, our focal points are: </p>
                <ol>
                    <li>Child Marriage</li>
                    <li>Teenage Pregnancies</li>
                    <li>Child Abuse</li>
                    <li>Online Child Protection</li>
                </ol>
                </article>
                <article className="six columns">
                <p>QAF is supporting children to enjoy their rights though the following approaches: </p>
                <ol>
                    <li>Create awareness among children on their rights.</li>
                    <li>Advocacy and lobbying.</li>
                    <li>Community Engagements.</li>
                    <li>Creating safe spaces within schools and communities.</li>
                    <li>Engaging the police and relevant stakeholders.</li>
                    <li>Parents engagement workshops.</li>
                    <li>Provision of pro-bono legal aid through partner firms</li>
                    <li>Connecting survivors of abuse to shelter homes</li>


                </ol>
                </article>
                </div>
               </div>
              </section>
                 
            </section>
            <Action />
            <Footer />
        </div>
        )
    }
}
