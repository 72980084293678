import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../Componets/header";
import Footer from "../../Componets/footer/footer";
import Intro from "../../Componets/page_intro/page_intro";
import "./staff.css";

export default class ritah extends Component {

  componentDidMount() {
    document.title = 'Ms ABASASIRA RITAH | Quiin Abenakyo Foundation'
  }

  render() {
    return (
      <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the innovative team" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                                <img src="/images/ritah.jpg" alt="" width="100%" />
                            </div>
                            <Link to="/"><h6 className="name">Ms ABASASIRA RITAH</h6></Link>
                            <span className="position">
                            Administrator
                            </span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
                  <p>Ritah is an Administrator and Personal Assistant at the Quin Abenakyo Foundation.
                       She oversees all the Administrative Functions and Reception duties. 
                        She is capable, determined, confident, and a well organized Administrator with extensive 
                        experience in supporting mid and senior level managers by providing them with consistent 
                        clerical support.</p>
                <p>Ritah Graduated from Makerere University Business School with a Bachelors of Office and Information
                     Management in 2020. She developed a  strong sense of devotion for the NGO sector and immediately 
                     after her final year at University, She started volunteering at the Foundation as a receptionist 
                     and  later was appointed as the Administrator. </p>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    );
  }
}
