import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Index from "./Pages/index";

import Founder from "./Pages/about/about_the_founder";
import Who_we_are from "./Pages/about/about_QAF";
import Our_approach from "./Pages/about/our_approach";
import Board from "./Pages/board/index";

import Speaker from "./Pages/board/rebecca"
import Vincent from "./Pages/board/vincent"
import Nataliey from "./Pages/board/nataliey"
import Ester from "./Pages/board/nambooka"
import Michelle from "./Pages/board/michelle"
import Prosper from "./Pages/board/prosper"

import Our_staff from "./Pages/about/our_staff";
import Ritah from "./Pages/team/ritah";
import Christine from "./Pages/team/christine"
import Okeny from "./Pages/team/okeny"

import Child_rights from "./Pages/what_we_do/child_rights";
import Education from "./Pages/what_we_do/education";
import Leadership from "./Pages/what_we_do/leadership";
import Reproductive_health from "./Pages/what_we_do/reproductive_health";
import water_and_sanitation from "./Pages/what_we_do/water_and_sanitation";

import Events from "./Pages/resources/events";
import Projects from "./Pages/resources/projects";

import Donate from "./Pages/donate";
import Volunteer from "./Pages/support/volunteer";

import Covid from "./Pages/covid_19_response";
import Stories from "./Pages/stories";

import "./App.css";
import "./skeleton.css";

const App = () => {
  const [progress, setProgress] = useState(0);

  return (<React.Fragment>
    <Router>
      <div className="App">
        <LoadingBar
          color="#000"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Switch>
          <Route path="/" exact component={Index} />

          <Route path="/covid_19_response" component={Covid} />
          <Route path="/about_quiin_abenakyo" component={Founder} />
          <Route path="/who_we_are" component={Who_we_are} />        
          <Route path="/our_approach" component={Our_approach} />

          <Route path="/board" component={Board} />
          <Route path="/hon_rebecca_kadaga" component={Speaker} />
          <Route path="/vincent_oriedo" component={Vincent} />
          <Route path="/nataliey_bitature" component={Nataliey} />
          <Route path="/ester_nambooka" component={Ester} />
          <Route path="/michelle_omamtecker" component={Michelle} />
          <Route path="/prosper_ahimibisibwe" component={Prosper} />


          <Route path="/our_staff" component={Our_staff} />
          <Route path="/staff/abasasira_ritah" component={Ritah} />
          <Route path="/staff/christine_adero" component={Christine} />
          <Route path="/staff/okeny_patrick" component={Okeny} />

          <Route path="/child_rights_and_protection" component={Child_rights} />
          <Route path="/education" component={Education} />
          <Route
            path="/water,_Sanitation_and_hygiene"
            component={water_and_sanitation}
          />
          <Route path="/leadership_and_governance" component={Leadership} />
          <Route
            path="/sexual_reproductive_health_and_rights"
            component={Reproductive_health}
          />

          <Route path="/events" component={Events} />
          <Route path="/projects" component={Projects} />

          <Route path="/volunteer" component={Volunteer} />

          <Route path="/stories" component={Stories} />
          <Route path="/donate" component={Donate} />
        </Switch>
        {() => setProgress(100)}
      </div>
    </Router>
    </React.Fragment>
  );
};

export default App;
