import React, { Component } from 'react'
import  Header  from '../Componets/header'
import Footer from '../Componets/footer/footer'
import Intro from '../Componets/page_intro/page_intro'
import  MenuList  from "../Componets/menu_list/index"
import Covid from "../Componets/covid"
import Action from '../Componets/action/index'

export default class index extends Component {

    componentDidMount() {
        document.title = 'Covid-19 Response | Quiin Abenakyo Foundation'
    }

    render() {
        return (
            <div>
            <Header />
            <section className="content">
               <Intro bgimg="/images/QAF_fighting_covid19.jpg" tag="Fighting Covid-9 by supporting the young people " />
               <MenuList />
               <Covid />
               <Action />
               </section>
            <Footer />
        </div>
        )
    }
}
