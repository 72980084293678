import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../Componets/header";
import Footer from "../../Componets/footer/footer";
import Intro from "../../Componets/page_intro/page_intro";
import "./staff.css";

export default class okeny extends Component {

  componentDidMount() {
    document.title = 'Okeny Patrick | Quiin Abenakyo Foundation'
}

  render() {
    return (
      <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the innovative team" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                <section
                  className="columns staff"
                  style={{ marginTop: "-150px", borderRadius: "none" }}
                >
                  <div className="image">
                    <img src="/images/okeny.jpg" alt="" width="100%" />
                  </div>
                  <Link to="/staff/okeny">
                    <h6 className="name">Mr Okeny Patrick</h6>
                  </Link>
                  <span className="position">IT Support</span>
                </section>
              </div>
              <div className="eight columns offset-by-three">
                <p>
                  Okeny Patrick is a full stack software developer with over 7
                  years of experience and a history of Mathematics and Art.
                  Having this background has made him one of the best developers
                  in this field. He is an expert in GCE(Google Cloud Engine),
                  Microservices, Kubernetics, Golang, PhP among others. He has
                  worked over the years as a Frontend/backend Engineer and a
                  DevOps Engineer. He use excellent communication skills to
                  elicit customer requirements and develop strong relationships
                  with key stakeholders throughout the project lifecycle.
                </p>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    );
  }
}
