import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import  MenuList  from "../../Componets/menu_list/index"

export default class our_approach extends Component {

    componentDidMount() {
        document.title = 'Our Approach | Quiin Abenakyo Foundation'
    }
    render() {
        return (
            <div>
            <Header />
            <section className="content">
                <Intro  bgimg="/images/QAF_approach.jpg" tag="We target disadvantaged groups" />
                <MenuList />
                <section className="twelve columns page_content">
                <div className="container ">
                    <h2>Our Approach</h2>
                    <p>Quiin Abenakyo Foundation provides holistic interventions designed to meet the needs of vulnerable adolescent girls especially those from rural areas and disadvantaged groups settings through advocacy, promotion of education, economic empowerment through skills development, mentorship and sensitization on sexual reproductive health rights.
The QAF works through establishing safe spaces in schools and communities through clubs. Our target age group is adolescent girls between the ages of 9-18 years and young women between the ages of 18-24 years.
 </p>
                </div>
            </section>
                

              
            </section>
            <Footer />
        </div>
        )
    }
}
