import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./botnav.css";

class Bottom extends Component {

  state ={
    nav: false
  }

  getnav=()=>{
    if (this.state.nav) {
      return "nav-max";
    } else {
      return "nav-min";
    }
  } 

  handlenav=()=>{
    this.setState({ nav: !this.state.nav});

  }

  getStyle() {
    if (this.props.scrolled) {
      return "nav-noshow";
    } else {
      return "nav-show";
    }
  }

  getLogoStyle() {
    if (this.props.scrolled) {
      return "min";
    } else {
      return "max";
    }
  }

  render() {
    return (
      <div className="bot-nav">
        <div className="container">
          <div className={"twohalf columns logo " + this.getLogoStyle()}>
            <Link to="/">
              <img src="/images/logo.png" width="145px" alt="" />
            </Link>
          </div>
          <span className="mobile" onClick={this.handlenav}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
          </span>
          <nav className={"offset-by-two nav ten columns "+ this.getnav() }>
            <ul>
              <Link to="/covid_19_response">
                <li
                  style={{ color: "#d7138d", backgroundColor: "transparent" }}
                >
                  COVID-19 Response
                </li>
              </Link>
              <li>
                About Us
                <ul>
                  <Link to="/about_quiin_abenakyo">
                    <li>About Quiin Abenakyo</li>
                  </Link>
                  <Link to="/who_we_are">
                    <li>Who we are</li>
                  </Link>
                  <Link to="/our_approach">
                    <li>Our Approach</li>
                  </Link>
                  <Link to="/board">
                    <li>Board Members</li>
                  </Link>
                  <Link to="/our_staff">
                    <li>Our Staff</li>
                  </Link>
                </ul>
              </li>
              <li>
                What we do
                <ul>
                  <Link to="/child_rights_and_protection">
                    <li>Child rights & protection</li>
                  </Link>
                  <Link to="/sexual_reproductive_health_and_rights">
                    <li>Sexual Reproductive Health & Rights</li>
                  </Link>
                  <Link to="/education">
                    <li>Education:Formal & Informal</li>
                  </Link>
                  <Link to="/water,_Sanitation_and_hygiene">
                    <li>Water, sanitation & hygiene</li>
                  </Link>
                  <Link to="/leadership_and_governance">
                    <li>Leadership & Governance</li>
                  </Link>
                </ul>
              </li>
              <li>
                Resources
                <ul>
                  <Link to="/events">
                    <li>Events</li>
                  </Link>
                  <Link to="/projects">
                    <li>Projects</li>
                  </Link>
                </ul>
              </li>
              <Link to="/stories">
                <li>Stories</li>
              </Link>
              <li>
                Support
                <ul>
                  <Link to="/volunteer">
                    <li>Volunteer</li>
                  </Link>
                </ul>
              </li>
              <a href="https://flutterwave.com/pay/quiinabenakyofoundation7nkq" target="blank">
                <li className="donate_btn">Donate</li>
              </a>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Bottom;
