import React, { Component } from 'react'
import './page_intro.css'

export default class page_intro extends Component {
    render() {
        return (
            <section className="twelve columns page_intro" style={{ backgroundImage: "url("+this.props.bgimg+")"}} >
                <div className="container">
                   <h3>{ this.props.tag}</h3>
                </div>
              
            </section>
        )
    }
}
