import React, { Component } from "react";
import "./index.css";

export default class index extends Component {

  state = {
    oneTime:false,
    monthly: false,
    i25: 0,
    i50: 0,
    i100: 0,
    i250: 0,
    amount:0
  }

  handleOneTime=()=>{
    if(this.state.monthly === true){
      return false
   }
    this.setState({ oneTime: !this.state.oneTime });
  }

  handleMonthly=()=>{
    if(this.state.oneTime === true){
       return false
    }
    this.setState({ monthly: !this.state.monthly });
  }

  handle25=()=>{

    if(this.state.i100  !== 0 || this.state.i250  !== 0 || this.state.i50  !== 0){
       return false
    }

    if(this.state.i25 !== 0 ){
      this.setState({ i25: 0});

    }else{
       this.setState({ i25: 25 });
    }
  }

  handle50=()=>{
    if(this.state.i100  !== 0 || this.state.i250  !== 0 || this.state.i25  !== 0){
       return false
    }
    if(this.state.i50 !== 0 ){
      this.setState({ i50: 0});

    }else{
       this.setState({ i50: 50 });
    }
  }

  handle100=()=>{
    if(this.state.i50  !== 0 || this.state.i250  !== 0 || this.state.i25  !== 0){
       return false
    }
    if(this.state.i100 !== 0 ){
      this.setState({ i100: 0});

    }else{
       this.setState({ i100: 100 });
    }

  }
  handle250=()=>{
    if(this.state.i50 !== "" || this.state.i100 !== "" || this.state.i25 !== ""){
       return false
    }
    if(this.state.i250 !== 0 ){
      this.setState({ i250: 0});

    }else{
       this.setState({ i250: 250 });
    }
  }

  getState =()=>{
    if (this.state.oneTime === true) {
      return "active";
    } else {
      return "inactive";
    }
  }

  getMonthlyState =()=>{
    if (this.state.monthly === true) {
      return "active";
    } else {
      return "inactive";
    }
  }
  get25State =()=>{
    if (this.state.i25 !== 0) {
      return "active";
    } else {
      return "inactive";
    }
  }
  get50State =()=>{
    if (this.state.i50 !== 0) {
      return "active";
    } else {
      return "inactive";
    }
  }
  get100State =()=>{
    if (this.state.i100 !== 0) {
      return "active";
    } else {
      return "inactive";
    }
  }

  get250State =()=>{
    if (this.state.i250 !== 0) {
      return "active";
    } else {
      return "inactive";
    }
  }



  render() {
    return (
      <section className="twelve columns what_we_do" style={{paddingBottom:'30px'}}>
        <div className="container ">
          <h2>Donate </h2>
          <p>
            With your support, we can amplify our impact. Support our work today
            by donating. Our system supports both mobile money and visa cards.
          </p>
          <div class="row">
            <div className={"two columns donate-seg offset-by-four " + this.getState()} onClick={this.handleOneTime}>
              One Time
            </div>
            <div className={"two columns donate-seg " + this.getMonthlyState()} onClick={this.handleMonthly}>Monthly</div>
          </div>
          <div class="row">
            <div className={"two columns donate-seg offset-by-two " + this.get25State()} onClick={this.handle25}>$25</div>
            <div className={"two columns donate-seg " + this.get50State()} onClick={this.handle50}>$50</div>
            <div className={"two columns donate-seg " + this.get100State()} onClick={this.handle100}>$100</div>
            <div className={"two columns donate-seg " + this.get250State()} onClick={this.handle250}>$250</div>
          </div>
          <div class="row">
            <div className="three columns  offset-by-four">
              <div className="six columns amount">Other ($)</div>
              <div className="six columns">
                <input type="number" name="amount" className="donate-input" style={{paddingRight:'10px'}} />
              </div>
            </div>
          </div>
          <div class="row">
            <input
              className=" offset-by-five two columns donate_btn page"
              type="submit"
              value="Donate"
            />
            <br/>
          </div>
        </div>
      </section>
    );
  }
}
