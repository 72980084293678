import React, { Component } from 'react'
import  Header  from '../Componets/header'
import Footer from '../Componets/footer/footer'
import Joinus from '../Componets/joinus/joinus'
import Rotator from '../Componets/rotator/rotator'
import WhatWeDo from '../Componets/what_we_do/what_we_do'
import Partners from '../Componets/partners/partners'
import Action from '../Componets/action/index'
import Map from '../Componets/map/mapContainer'

import './index.css'

export default class index extends Component {
    
    componentDidMount() {
        document.title = 'Home | Quiin Abenakyo Foundation'
    }

   
    render() {

        const location = {
            address: 'Quiin Abenakyo Foundation.',
         
            lat: 0.3032744,
          lng: 32.6157071,
          
          } // our location object from earlier
        return (
            <div>
                <Header />
                <section className="content">
                  <Rotator />
                  <Joinus />
                  <WhatWeDo />
                  <Partners />
                  <Action />
                  <Map location={location} zoomLevel={15} /> 
                </section>
                <Footer />
            </div>
        )
    }
}
                  //
                  

