import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'
import Actions from '../../Componets/action/index'

export default class leadership extends Component {

  componentDidMount() {
    document.title = 'Leadership and governance | Quiin Abenakyo Foundation'
}
    render() {
        return (
            <div>
            <Header />
            <section className="content">
              <Intro bgimg="/images/governance_full.jpg" tag="Empowering a new wave of strong future leaders"/>
              <MenuList />
              <section className="twelve columns page_content">
               <div className="container ">
                <h2>leadership and governance</h2>

              <p>Participation of women and girls in the electoral processes and leadership positions remains low. </p>
              <p>For example, as of June 2016, Uganda had only 28 women Ministers out of the 80. This representation is not
                 only low in the cabinet but other leadership positions ranging from schools to universities and communities.
                  This can be attributed to the socio-cultural beliefs, limited access to politics related information, and 
                  limited leadership skills.</p>
                <p>In order for women and girls to contribute to the developmental change in their communities, they need to be
                   part of the decision making through participating in the electoral and democratic processes at all levels. </p>
                <p>At the QAF, we aim at promoting women and girls leadership capacities and participation as contestants, supporters or voters. </p>
                <p>Our approaches include;</p>
                <ol>
                  <li>Creating a network of women in politics that can offer support to aspiring leaders</li>
                  <li>Conducting leadership training programs in schools</li>
                  <li>Conducting civic education and participation campaigns on social media and in communities</li>
                </ol>
              </div>
              </section>
            </section>
            <Actions />
            <Footer />
        </div>
        )
    }
}
