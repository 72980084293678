import React, { Component } from 'react'
import  Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import MenuList from '../../Componets/menu_list'

export default class events extends Component {

    componentDidMount() {
        document.title = 'Events | Quiin Abenakyo Foundation'
    }

    render() {
        return (
            <div>
            <Header />
            <section className="content">
            <Intro bgimg="/images/QAF_president_with_the_queen.jpg" tag="Come and join us as we celebrate the girlchild" />
            <MenuList />
            <section className="twelve columns page_content">
                <div className="container ">
                <h2>QAF Events</h2>
                <p>As you know because of covid-19 most of the events in schools were postponed. But as soon as we have you will be the first to know</p>
             </div>
             </section>

            </section>
            <Footer />
        </div>
        )
    }
}
