import React, { Component } from 'react'
import './index.css'

export default class MenuList extends Component {
    render() {
        return (
            <div className="menu_list">
                <div className="container">
                <ul>
                    <li>About Quiin Abenakyo</li>
                    <li>Who we are </li>
                    <li>Vision & Mission</li>
                    <li>Objectives </li>
                    <li>Our Approach</li>
                    <li>Our Staff </li>
                </ul>
                </div>
          </div>
        )
    }
}
