import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Header  from '../../Componets/header'
import Footer from '../../Componets/footer/footer'
import Intro from '../../Componets/page_intro/page_intro'
import './staff.css'

export default class christine extends Component {
    
      componentDidMount() {
        document.title = 'Adero Christine | Quiin Abenakyo Foundation'
    }

    render() {
        return (
            <div>
        <Header />
        <section className="content">
          <Intro bgimg="/images/staff.png" tag=" Meet the innovative team" />
          <section
            className="twelve columns what_we_do"
            style={{ paddingBottom: "100px" }}
          >
            <div className="container ">
              <div className="row">
                    <section className="columns staff" style={{marginTop:"-150px",borderRadius:'none'}}>
                            <div className="image">
                            <img src="/images/christine.jpg" alt="" width="100%" />
                            </div>
                            <Link to="/staff/christine_adero"><h6 className="name">Ms CHRISTINE ADERO</h6></Link>
                            <span className="position">
                            Programs Manager
                            </span>
                        </section>
              </div>
              <div className="eight columns offset-by-three">
                  <p>Christine Adero is a Ugandan fashion model with extensive dynamic experience in the fields of data 
                      science, digital security, and working in the areas of ; youth and gender sensitive programming,
                       proposal development, and project management.</p>
                <p>Christine has earned 6 years experience in the Non-Profit sector, working in programs that empower 
                    the youth mainly the girl child, by creating safe spaces with the goal of providing them with a 
                    platform for them to freely express themselves, tackle issues that affect them, gain skills,
                     connect to opportunities and also enlarge their knowledge on a variety of subjects. </p>
                     <p>In addition, Christine is a 2019 Unleash Global Innovation Lab Alumni , a Youth Champion for Every Hour Matters Campaign under Together For Girls ℅ UNAids where she contributed to a post rape care toolkit for Uganda, A SafeSister Program Fellow where she implemented  a project to create awareness on Internet safety and Online GBV against young women and girls, a selected speaker at the World Youth Forum 2018 where she shared a Panel with His Excellency President Abdel el-Sisi of Egypt, a Young African Leaders Initiative (YALI) fellow, a DefendDefenders DOC-it 2018 fellow working on data analysis and visualization while using data for advocacy, a CREA Alumni under the Feminist Leadership and Movement Building and Rights Institute of East Africa, and also worked on a number of programs on Gender and GBV with the United States Peace Corps.</p>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
    );
  }
}